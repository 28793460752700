export default {
  "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "error": {
    "router": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte ne vous donne pas accès à cette fonctionnalité."])},
    "redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redirection impossible"])},
    "emptyField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ ne peut pas être vide"])},
    "dbError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue"])},
    "noInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture"])},
    "noConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder à la consommation"])},
    "noContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun contrat"])},
    "newUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de créer cet utilisateur"])},
    "deleteUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer cet utilisateur"])},
    "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de déployer les modèles"])},
    "notOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez avoir un compte propriétaire pour inviter des utilisateurs"])},
    "companyNotVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez avoir un compte vérifié pour inviter des utilisateurs"])},
    "getUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de récupérer les utilisateurs"])},
    "tokenExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre session a expirée"])},
    "accountNotIdentified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'identification votre compte"])},
    "companyNotIdentified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'identification votre organisation"])},
    "roleNotIdentified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'identification votre role"])},
    "roleNewUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur d'attribution du role au nouvel utilisateur"])},
    "notAuthorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à faire ceci"])},
    "editUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour les informations de l'utilisateur"])},
    "wrongCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet utilisateur ne semble pas appartenir à votre organisation"])},
    "donwloadInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors du téléchargement de la facture"])},
    "minimumOneOwner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre organisation doit posséder au moins un propriétaire"])},
    "idNotCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de copier l'identifiant de cet utilisateur"])},
    "email": {
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoi de l'e-mail impossible"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'envoyer l'e-mail de confirmation"])},
      "sendInvitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'envoyer l'e-mail d'invitation"])},
      "wrongFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mauvais format d'e-mail"])},
      "wrongAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail inconnue pour cet utilisateur"])},
      "verify": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de vérifier votre adresse e-mail"])},
      "verifyNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de vérifier votre nouvelle adresse e-mail"])},
      "notVerified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre adresse e-mail n'a pas encore été vérifiée"])},
      "notModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il ne semble pas que l'adresse e-mail de ce compte ait demandé à être modifiée"])}
    },
    "auth": {
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription impossible"])},
      "registrationNotSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'inscription de votre compte n'a pas été finalisée"])},
      "userRegistrationNotSucceeded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'inscription de ce compte n'a pas été finalisée"])},
      "alreadyRegistered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous possédez déjà un compte"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom d'utilisateur doit être au format d'une adresse e-mail"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion impossible"])},
      "userDoesNotExist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'utilisateur n'existe pas"])},
      "unknownUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur inconnu"])},
      "unknownUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur inconnu"])},
      "usernameAlreadyExists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce nom d'utilisateur est dejà utilisé"])},
      "wrongPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe incorrect"])},
      "differentPasswords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mots de passe doivent être identiques"])},
      "passwordStrength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 12 caractères, une minuscule, une majuscule, un chiffre et un caractère spécial."])},
      "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de mettre à jour votre mot de passe"])},
      "initPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'initialiser votre mot de passe"])},
      "passwordAlreadyInitialized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a déjà été initialisé"])},
      "passwordNotInitialized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe n'a pas encore été initialisé"])}
    },
    "salesforce": {
      "accessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès refusé"])},
      "inactiveOrg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation bloquée, férmée ou suspendue"])},
      "inactiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur inactif"])},
      "noAccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès non autorisé"])},
      "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de trouver l'utilisateur"])},
      "server": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de demande d'autorisation atteinte"])},
      "auth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Authentification à Salesforce impossible"])}
    },
    "environment": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible d'accéder aux environnements"])},
      "deleteUnknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet environnement n'existe pas ou a déja été supprimé"])},
      "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer cet environment"])},
      "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de sécuriser cet environment"])}
    },
    "webdocExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document a expiré"])},
    "webdocError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur à eu lieu lors de la récupération du document"])},
    "webdocAlreadySign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà signé le document"])},
    "webdocNotFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document trouvé"])},
    "webdocSignatureError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur a eu lieu lors de la signature du document"])},
    "webdocActionAlreadyRegistred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez dejà effectué une des actions demandé"])},
    "webdocNoContentDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document n'est relié à la procédure de signature"])},
    "downloadWebdoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenu lors du téléchargement du fichier"])},
    "webdocNotDone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas télécharger le document tant que tout les signataires n'ont pas signé"])},
    "webdocEveryoneMustSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout les signataires n'ont pas encore signé"])},
    "webdocOops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OUPS"])},
    "webdocErrorWhileLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur au chargement"])},
    "webdocIssueWhenLoading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un problème a eu lieu au chargement du document"])},
    "webdocnoStakeHolders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun destinataire n'est associé à cette procédure de signature"])}
  },
  "success": {
    "emailResend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail renvoyé !"])},
    "registrationCompleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes maintenant prêt à utiliser l'App! Connectez-vous pour continuer"])},
    "userDeleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur supprimé"])},
    "userInvited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouvel utilisateur a été invité à rejoindre l'App"])},
    "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles déployés avec succès"])},
    "connectEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement connecté avec succès"])},
    "secureEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement sécurisé avec succès"])},
    "deleteEnv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement supprimé avec succès"])},
    "userModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les informations de l'utilisateur ont bien été mises à jour"])}
  },
  "consoleError": {
    "emailLS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur de récupération de l'adresse e-mail dans le Local Storage :"])},
    "salesforceAuth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la demande d'authentification :"])}
  },
  "img": {
    "gnxLogo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logo de Gonexa"])}
  },
  "app": {
    "cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions générales"])},
    "synthesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
    "consumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures"])},
    "users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil & utilisateurs"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnements"])},
    "deploiement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connect"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide & contact"])},
    "disconnect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "loggedAs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecté en tant que"])},
    "gonexaTeam": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'équipe Gonexa"])},
    "legalNotice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
    "finishConfig": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finaliser la configuration"])},
    "billingConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Factures & Consommations"])}
  },
  "errorPage": {
    "notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page non trouvée"])},
    "joke": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Même nos techniciens cherchent toujours"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacter le support"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner à l'accueil"])}
  },
  "home": {
    "hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur votre espace client"])},
    "workspaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer la liste des environnements connectés à l'App"])},
    "deployment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier les modèles de document d'un environnement à un autre"])},
    "access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder"])},
    "connectProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter ma production"])},
    "connectSf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion à Salesforce"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du compte"])},
    "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification en cours..."])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte validé"])},
    "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande refusée"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de contacter le service support"])},
    "connectMyEnvironments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter mes environnements"])},
    "connectionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activez votre production pour accéder à vos factures & vos consommations (après vérification de votre contrat par Gonexa)"])},
    "verifInProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du compte en cours..."])},
    "verificationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos équipes procèdent à une vérification"])},
    "connectionProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La connexion autorise la génération de document"])}
  },
  "auth": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue"])},
    "registerIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire sur l'App Gonexa"])},
    "loginIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à l'App Gonexa à l'aide de votre nom d'utilisateur"])},
    "disconnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez été déconnecté"])},
    "noAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de compte ?"])},
    "alreadyAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ?"])},
    "inputs": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
      "newEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle adresse e-mail"])}
    },
    "buttons": {
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
      "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
      "createAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon compte"])},
      "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
      "validAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider mon compte"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "goToApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir sur l'App"])}
    },
    "emails": {
      "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer votre e-mail"])},
      "sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail de vérification à été envoyé à l'adresse suivante :"])},
      "consult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez votre boîte de réception pour finaliser votre inscription."])},
      "consultReinit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez votre boîte de réception pour finaliser la réinitialisation."])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer un e-mail"])},
      "updating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de votre adresse e-mail"])},
      "updatingDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse e-mail de ce compte a été modifiée avec succès."])},
      "consultReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultez votre boîte de réception pour finaliser la réinitialisation."])},
      "resent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un nouvel e-mail d'invitation a été envoyé à "])},
      "modifiedResent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail de confirmation a été envoyé à cet utilisateur. Une fois l'adresse e-mail confirmée, elle sera mise à jour."])}
    },
    "password": {
      "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification du mot de passe"])},
      "defineFor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir le mot de passe pour "])},
      "defineNew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Définir votre nouveau mot de passe"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser mon mot de passe"])},
      "resetIntro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre adresse e-mail et nous vous enverrons un lien pour réinitialiser votre mot de passe"])}
    }
  },
  "billing": {
    "synthesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse de mes factures"])},
    "overallStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut global"])},
    "lastInvoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière facture le"])},
    "lastAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de la dernière facture"])},
    "upToDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiements à jour"])},
    "toPay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture à régler"])},
    "late": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retard de paiements"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des factures"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de facture"])},
    "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTC"])},
    "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement de la facture..."])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune facture n'est disponible pour l'instant"])}
  },
  "consumption": {
    "synthesis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse de ma consommation"])},
    "generatedDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents générés"])},
    "docTemplates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèles de documents"])},
    "templates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modèles"])},
    "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimité"])},
    "signatures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatures électroniques"])},
    "outOf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
    "evolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évolution sur les 12 derniers mois"])},
    "actual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation actuelle"])},
    "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite de consommation"])}
  },
  "deployment": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déploiement des modèles"])},
    "deployModels": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déployer les modèles"])},
    "sourceEnvironment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement source"])},
    "targetEnvironment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement cible"])},
    "modelsList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des modèles"])},
    "modelName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du modèle"])},
    "fileName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du fichier"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
    "size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille"])},
    "lastSend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier envoi azure"])},
    "unknownFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inconnu"])},
    "research": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])},
    "noSourceSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner l'environnement source"])},
    "noDestinationSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner l'environnement destination"])},
    "noModelSelected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner au moins un modèle de document"])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun modèle de document sur cet environnement"])}
  },
  "universityCard": {
    "training": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formez-vous"])},
    "gonexaAndMe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gonexa & moi"])}
  },
  "ticketCard": {
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une question ?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrir un ticket"])}
  },
  "sign": {
    "invitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes invité.e à signer ce document :"])},
    "invitations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes invité.e à signer ces documents :"])},
    "consultation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultation"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature"])},
    "youSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous signez en tant que"])},
    "notYou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce n'est pas vous ?"])},
    "sender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expéditeur :"])},
    "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire :"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires :"])},
    "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document(s) :"])},
    "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents :"])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre document a été signé"])},
    "quit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter la page"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
    "documentAvaible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document est à présent disponible en cliquant sur le lien ci-dessous"])},
    "downloadDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger mon document"])},
    "documentHasBeenSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document a été signé"])},
    "documentSoonAvaiable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourrez télécharger le document sur cette page une fois que tout les signataires auront signé"])},
    "preview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aperçu"])},
    "the": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le"])},
    "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
    "hasBeenSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé le"])},
    "documentLinkHasExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien du document a expiré"])},
    "linkExpiredText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapprochez-vous de l’expéditeur pour qu’il puisse renvoyer un nouveau lien"])},
    "sentMailToExpeditor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer un mail à l’expéditeur"])},
    "documentHasBeenValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le document a été validé"])},
    "documentSoonAvaiableValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pourrez télécharger le document sur cette page une fois que tout les signataires auront validé"])},
    "backToDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir au document"])},
    "confirmSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la signature du document ?"])},
    "confirmValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la validation du document ?"])},
    "soonAvaiableDownload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible"])},
    "validationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes sur le point de finaliser le processus, vous pourrez, à la suite, télécharger le document final"])},
    "validateValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le document"])},
    "validateSignature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la signature"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "checkTheDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez consulter le document complet"])},
    "backtoDoc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir au document"])}
  },
  "contractCard": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon contrat"])},
    "clientSince": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client depuis le"])},
    "renew": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du renouvellement"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modalité de paiement"])},
    "gnxSuit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes solutions gonexa"])}
  },
  "users": {
    "myInfos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes informations"])},
    "contractContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact contractuel"])},
    "techContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact technique"])},
    "billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
    "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs"])},
    "noActiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur actif"])},
    "oneActiveUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateur actif"])},
    "activeUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["utilisateurs actifs"])},
    "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel utilisateur"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom complet"])},
    "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "limited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limité"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire"])},
    "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
    "creation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'utilisateur"])},
    "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression"])},
    "confirm1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous"])},
    "confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmer la suppression"])},
    "confirm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de l'utilisateur ?"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'utilisateur"])},
    "modification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modification"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
    "noUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur actif, cliquez sur \"Nouvel utilisateur\" pour en ajouter"])},
    "idCopied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant copié dans le presse-papier"])}
  },
  "workspaces": {
    "myProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ma production"])},
    "orgId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORG ID"])},
    "orgName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORG NAME"])},
    "connectProd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter ma production"])},
    "sandboxList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des sandboxes"])},
    "noActivSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune sandbox active"])},
    "oneActivSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 sandbox active"])},
    "activSandboxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sandboxes actives"])},
    "connectSandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter Sandbox"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "connected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectée"])},
    "notConnected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non connectée"])},
    "notSecured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sécurisée"])},
    "secure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécuriser"])},
    "connect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "workspace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Environnement"])},
    "deletion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression"])},
    "confirm1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous"])},
    "confirm2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["confirmer la suppression du lien"])},
    "confirm3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entre la sandbox & Gonexa ? Cette opération ne supprime pas la sandbox."])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le lien"])},
    "emptyTable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Sandbox ajoutée, merci de cliquer sur \"Connecter Sandbox\""])},
    "noConnection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non connectée"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
    "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
    "connectionToSalesforce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecter un environnement Salesforce"])},
    "production": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production"])},
    "sandbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sandbox"])},
    "configuration1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quel"])},
    "configuration2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type d’environnement"])},
    "configuration3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["souhaitez vous connecter ?"])}
  },
  "webdoc": {
    "downloading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement du document..."])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])}
  }
}