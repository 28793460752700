
import { Vue, Options } from 'vue-class-component';
import WebdocService from '@/services/webdoc.service';
import { StakeHolder, Webdoc, WebdocAction } from '@/types/webdoc.type';
import { useToast } from 'vue-toastification';
import { AxiosError } from 'axios';
import WebdocPopup from './WebdocPopup.vue';
import WebdocError from './WebdocError.vue';

@Options({
	components: {
		WebdocPopup,
		WebdocError,
	},
})
export default class SignView extends Vue {
	docContainer!: HTMLElement | null;
	docControlls!: HTMLElement | null;
	docView!: HTMLElement | null;
	docContent!: HTMLElement | null;

	webdoc: Webdoc | undefined;
	consultedDocuments: string[] = [];
	actions: WebdocAction[] = [];
	webdocToken = '';

	consultation = true;
	interaction = false;
	signature = false;
	signatureLoaded = true;
	webdocLoaded = false;
	consulted = false;
	docLoading = false;
	expired = false;
	confirmation = false;
	validation = false;
	hasEveryStakeHolderSigned = false;
	menuOpen = false;
	currentLang = false;
	screenState = true;
	allowZoom = false;
	scalingMobile = false;

	lang = 'fr';
	toast = useToast();
	zoom = 1;
	progression = 0;
	error: string | undefined = undefined;
	lastTouchDistance!: number | null;

	async mounted() {
		const screenStateLS = localStorage.getItem('APP_NB');
		if (screenStateLS) this.screenState = screenStateLS == 'true' ? true : false;
		this.manageScreenSize();
		window.addEventListener('resize', this.manageScreenSize);

		const lang = localStorage.getItem('APP_LG');
		this.currentLang = lang == 'en' ? true : false;
		this.docContainer = this.$refs.docContainer as HTMLElement;
		this.docControlls = this.$refs.docControlls as HTMLElement;
		this.docContent = this.$refs.docContent as HTMLElement;
		this.docView = this.$refs.docView as HTMLElement;
		const webdocToken = this.$route.params.token.toString();
		this.webdocToken = webdocToken;
		await WebdocService.getWebdoc(webdocToken)
			.then((webdoc) => {
				this.webdoc = webdoc;
				if (this.webdoc.status.toLocaleLowerCase() == 'expired') {
					this.expired = true;
				}
			})
			.catch((error) => {
				console.error(error);
				if (error.response) {
					console.error('Erreur lors de la récuperation de signature ');
					console.error(`Erreur : ${error.response.data}`);
					switch (error.response.data) {
						case 'WebdocExpired': {
							this.expired = true;
							break;
						}
						case 'NoWebdocFound': {
							this.error = 'error.webdocNotFound';
							break;
						}
						case 'NoStakeholdersFound': {
							this.error = 'error.webdocnoStakeHolders';
							break;
						}
						case 'NoContentDocument': {
							this.error = 'error.webdocNoContentDocument';
							break;
						}
						default: {
							this.error = 'error.webdocError';
							break;
						}
					}
				} else {
					this.error = 'error.webdocError';
				}
			});
		this.webdocLoaded = true;
		if (this.webdoc) {
			window.document.title = this.webdoc.name;
			this.setWebdocMode();
			this.hasEveryStakeHolderSigned = this.webdoc.recipients.every(
				(stakeHolder) => stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
			);
			if (
				!this.webdoc.recipients.every(
					(stakeHolder) => stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
				) &&
				!this.expired
			) {
				window.addEventListener('resize', this.adjustDocControlls);
				window.addEventListener('resize', this.adjustDocumentContent);

				this.adjustDocControlls();
				this.$nextTick(async () => {
					await this.loadHtmlDoc();
					this.adjustDocumentContent();
				});
			}
		}
	}

	public changeLanguage() {
		this.$i18n.locale = this.currentLang ? 'fr' : 'en';
		localStorage.setItem('APP_LG', this.$i18n.locale);
		window.dispatchEvent(new Event('langChanged'));
	}

	manageScreenSize() {
		if (window.innerWidth <= 1100) {
			if (this.screenState) {
				this.screenState = !this.screenState;
				localStorage.setItem('APP_NB', this.screenState.toString());
			}
			if (window.innerWidth <= 768 && this.allowZoom) {
				this.zoom = (0.47 * window.innerWidth) / 428;
			}
		} else if (window.innerWidth > 1100 && !this.screenState) {
			this.screenState = !this.screenState;
			localStorage.setItem('APP_NB', this.screenState.toString());
			this.zoom = 1;
		}
		this.adjustDocumentContent();
		this.fixDocumentAlignement();
		this.adjustDocControlls();
	}

	changeLanguageInteraction() {
		Array.from(document.getElementsByClassName('sign-preview__header-text')).forEach((element) => {
			element.textContent = this.$t('sign.preview');
		});
		Array.from(document.getElementsByClassName('sign-preview__date')).forEach((element) => {
			element.textContent = this.$t('sign.the') + ' ' + new Date().toLocaleDateString();
		});
	}

	/**
	 * Contrôle les bouton + et - pour le zoom
	 */
	zoomControl(type: string) {
		if (this.allowZoom) {
			if (type == 'decrease') {
				if (this.zoom > 0.1 && this.zoom - 0.1 > 0) this.zoom = this.zoom - 0.1;
			} else {
				if (this.zoom < 2) this.zoom = this.zoom + 0.1;
			}
			this.adjustDocumentContent();
		}
		this.fixDocumentAlignement();
	}

	handleZoomInput(event: Event) {
		const target = event.target as HTMLInputElement;
		if (target != null) {
			const zoomValue = Number(target.value);
			if (zoomValue != null && zoomValue <= 200 && zoomValue >= 2) {
				this.zoom = zoomValue / 100;
				this.fixDocumentAlignement();
			} else {
				if (zoomValue != null && zoomValue > 200) this.zoom = 2;
				else if (zoomValue != null && zoomValue <= 2) this.zoom = 0.1;

				this.fixDocumentAlignement();
			}
		}
	}

	fixDocumentAlignement() {
		this.$nextTick(() => {
			const documentContent = document.getElementById('documentContent');
			if (documentContent && documentContent.parentElement) {
				const documentContentRect = documentContent.getBoundingClientRect();
				const documentControllerRect = documentContent.parentElement.getBoundingClientRect();
				const newWidth = (documentControllerRect.width - documentContentRect.width) / 2;
				if (newWidth >= 0) {
					documentContent.style.marginLeft = newWidth + 'px';
					documentContent.style.paddingLeft = '0px';
					documentContent.style.paddingRight = '0px';
				} else {
					documentContent.style.marginLeft = 0 + 'px';
					documentContent.style.paddingLeft = '2rem';
					documentContent.style.paddingRight = '2rem';
				}
				if (this.zoom < 1) {
					documentContent.parentElement.style.setProperty(
						'--contentWidth',
						`${documentControllerRect.height - documentContentRect.height}px`
					);
				} else {
					documentContent.parentElement.style.setProperty('--contentWidth', `0px`);
				}
			}
		});
	}

	nameToIcon(name: string): string {
		let majs = '';
		let count = 0;
		const specialChar = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
		for (let i = 0; i < name.length; i++) {
			if (name[i] == name[i].toUpperCase() && !specialChar.test(name[i])) {
				count++;
				majs += name[i];
				if (count >= 2) break;
			}
		}

		if (count < 2) {
			majs = name.substring(0, 2);
		}

		return majs;
	}

	getRecipients(stakeHolder: StakeHolder[]): StakeHolder[] {
		return stakeHolder.filter((x) => x.type != 'Owner');
	}

	async closeWebdocPopup() {
		this.validation = false;
		this.adjustDocControlls();
		await this.loadHtmlDoc();
		this.adjustDocumentContent();
	}

	setWebdocMode() {
		if (this.webdoc) {
			const signIndex = this.webdoc.recipients.findIndex((elem) => elem.id == this.webdoc?.signatory.id) + 1;
			this.webdoc.interactions.forEach((interaction) => {
				if (interaction.recipient == `s${signIndex}`) {
					this.consultation = false;
					const splittedTag = interaction.tag.replace('{!', '').replace('}', '').split('|');
					if (splittedTag.length == 1) {
						this.signature = true;
					} else if (splittedTag.length > 1) {
						this.interaction = true;
					}
				}
			});
			if (!this.interaction && !this.signature) this.consultation = true;
		}
	}

	/**
	 * Charge TOUT les fichiers en html
	 */
	async loadHtmlDoc(index = 0) {
		let updateFunction: (() => void)[] = [];
		if (this.webdoc != undefined) {
			const element = this.webdoc.contentDocuments[index];
			this.docLoading = true;
			let file = await WebdocService.getBlobFromContentId(
				this.webdoc.instanceUrl,
				this.webdoc.refreshToken,
				element.id
			);
			this.docLoading = false;
			const htmlContainer = document.createElement('div');
			const containerId = `document-container-${element.id}`; // ID unique pour chaque container
			htmlContainer.id = containerId; // Associer un ID
			htmlContainer.classList.add('document-container');

			//retire les balises qui coupe la balise signature
			if (index == 0) file = file.replaceAll('<span class="stl_06">', '');

			htmlContainer.innerHTML = file;

			const docContainer = document.getElementById('documentContent') as HTMLDivElement;

			//on affiche les pages et ont leur met les ombres
			if (docContainer) {
				docContainer.appendChild(htmlContainer);

				//pour le premier document (tout les autres documents sont des annexes)
				if (index == 0)
					if (htmlContainer) {
						const signIndex =
							this.webdoc.recipients.findIndex((elem) => elem.id == this.webdoc?.signatory.id) + 1;
						const spans = Array.from(htmlContainer.getElementsByTagName('span'));
						const spansLength = spans.length;

						//pour tout les spans
						let tagsBuffer: string[] = [];
						let spansBuffer: HTMLSpanElement[] = [];
						let recordBuffer = false;
						for (let i = 0; i < spansLength; i++) {
							const textContent = spans[i].textContent;

							//Si la balise span a du contenu
							if (textContent) {
								if (!recordBuffer && textContent.replaceAll(' ', '').includes('{')) {
									recordBuffer = true;
								}
								if (recordBuffer && spans[i]) {
									tagsBuffer.push(textContent);
									spansBuffer.push(spans[i]);
								}
								if (recordBuffer && textContent.replaceAll(' ', '').includes('}')) recordBuffer = false;

								const bufferedTag = tagsBuffer.join('').replaceAll(/\s/g, '');
								const isMultipleLineTag = /{!.*?}/g.test(bufferedTag);
								const isOneLineTag = /{!.*?}/g.test(textContent);

								//Si le contenu de la span contient une balise
								if (isOneLineTag || isMultipleLineTag) {
									docContainer.style.display = 'block';
									if (isOneLineTag) {
										let found = spans[i];
										const matches = found.textContent?.match(/{!.*?}/g);

										if (matches) {
											found.innerHTML = found.innerHTML.replace(
												/{!.*?}/g,
												'<span style="color:white">' + '$&' + '</span>'
											); //on met les balise qui correspondent à la regex, en blanc

											matches.forEach((match) => {
												const whiteNode = Array.from(
													htmlContainer.getElementsByTagName('span')
												).find((elem) => {
													const elemMatches = elem.textContent?.match(/{!.*?}/g);
													if (elemMatches) {
														if (
															elemMatches.length == 1 &&
															elemMatches[0] == match &&
															elem.style.color == 'white'
														)
															return elem;
													}
												}); //on récupère la balise en blanc qui correspond à la balise pour récuperer sa position

												const splittedAction = match
													.replace('{', '')
													.replace('}', '')
													.replace('!', '')
													.split('|'); //on recupere chaque partie de la balise

												updateFunction.push(() =>
													this.insertInteractionNode(
														splittedAction,
														signIndex,
														whiteNode,
														found,
														htmlContainer,
														match
													)
												);
											});
										}
									} else {
										let found = spansBuffer[0]; //on met les balise qui correspondent à la regex, en blanc
										for (let j = 0; j < tagsBuffer.length; j++) {
											const spanTag = spansBuffer[j];
											const matches = spanTag.textContent?.match(tagsBuffer[j]);
											if (matches) {
												if (spanTag) {
													const newContent = spansBuffer[j].textContent?.replace(
														matches[0],
														'<span style="color:white">' + '$&' + '</span>'
													);
													if (newContent) spansBuffer[j].innerHTML = newContent;
												}
											}
										}

										const splittedAction = bufferedTag
											.replace('{', '')
											.replace('}', '')
											.replace('!', '')
											.split('|'); //on recupere chaque partie de la balise

										if (found)
											updateFunction.push(() =>
												this.insertInteractionNode(
													splittedAction,
													signIndex,
													undefined,
													found,
													htmlContainer,
													bufferedTag
												)
											);
									}
									spansBuffer = [];
									tagsBuffer = [];
								}
							}
						}
					}

				const elements = docContainer.querySelectorAll('.stl_');
				elements.forEach((elem) => {
					const htmlElem = elem as HTMLElement;
					htmlElem.style.marginBottom = '40px';
					htmlElem.style.boxShadow = '0px 0px 20px rgba(149, 149, 149, 0.2509803922)';
				});
			}
			//quand le document apparait sur les 1/3 de l'ecran on le valide
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.intersectionRatio > 0.3) {
							if (this.consultedDocuments.indexOf(containerId) < 0)
								this.consultedDocuments.push(containerId);
						}
					});
				},
				{ threshold: 0.33 }
			);
			if (htmlContainer.lastElementChild) observer.observe(htmlContainer.lastElementChild);

			if (index != this.webdoc.contentDocuments.length - 1) {
				this.loadHtmlDoc(index + 1);
			} else {
				this.setConsultedOnScroll();
				this.initWheelZoomControl();
				this.allowZoom = true;
				this.manageScreenSize();
				this.fixDocumentAlignement();
				this.initTouchZoomControl();
				this.$nextTick(() => {
					updateFunction.forEach((func) => func());
				});
			}
		}
	}

	insertInteractionNode(
		splittedAction: string[],
		signIndex: number,
		whiteNode: HTMLSpanElement | undefined,
		found: HTMLSpanElement,
		htmlContainer: HTMLDivElement,
		match: string
	) {
		if (splittedAction.length > 0 && splittedAction[0] == `s${signIndex}` && this.webdoc) {
			const top =
				whiteNode == undefined
					? found.getBoundingClientRect().top - htmlContainer.getBoundingClientRect().top
					: whiteNode.getBoundingClientRect().top - htmlContainer.getBoundingClientRect().top;
			const left =
				whiteNode == undefined
					? found.getBoundingClientRect().left - htmlContainer.getBoundingClientRect().left
					: whiteNode.getBoundingClientRect().left - htmlContainer.getBoundingClientRect().left; //on recupere les coordonées de l'élément à mettre
			let node = '';
			let action: WebdocAction = {
				tag: match,
				value: '',
				type: 'Signature',
			};

			const mappedAction = this.webdoc.interactions.find((elem) => {
				if (elem.tag == match) return elem;
			}); //on recupere les interactions qui sont dans salesforce
			if (mappedAction) {
				this.consultation = false; // on définit que ce webdoc n'est pas fait pour etre que consulté
				if (splittedAction.length == 1) {
					//si c'est une signature
					node = this.getSignPreview(
						this.webdoc.signatory.firstName + ' ' + this.webdoc.signatory.lastName,
						mappedAction.width.toString(),
						mappedAction.height.toString(),
						top.toString(),
						left.toString()
					);
					this.signature = true; //on définit que le document doit être signé par la personne
				}
				/*else {
														this.interaction = true; // on définit qu'il y a des interractions
														//const contextApiName = splittedAction[1]; //on recupere l'objet

														switch (mappedAction.type) {
															case 'text': {
																node = `<input type="text" maxlength="${
																	mappedAction.maxLength
																}"  style="float: left;width: ${mappedAction.width
																	.toString()
																	.trim()}px;height: ${mappedAction.height
																	.toString()
																	.trim()}px;position: relative;z-index:100; padding: 0; margin:0; top: ${top}px; left: ${left}px; position: absolute; outline: none; border: solid 1px rgb(0,0,0, .1); border-radius: 6px;background: #FCFCFC;"/>`;
																action.type = 'String';
																break;
															}
															default: {
																console.error(
																	'error with input type ' + mappedAction.type
																);
															}
														}
													}*/
				this.actions.push(action);
			}

			htmlContainer.insertAdjacentHTML('afterbegin', node);
		}
	}

	initTouchZoomControl() {
		const docController = document.getElementById('documentController') as HTMLDivElement;
		docController.addEventListener('touchstart', (e) => {
			if (e.touches.length === 2) {
				e.preventDefault();
				this.scalingMobile = true;
				this.lastTouchDistance = this.getDistance(e.touches[0], e.touches[1]);
			}
		});
		docController.addEventListener('touchmove', (e) => {
			if (e.touches.length === 2 && this.lastTouchDistance !== null) {
				e.preventDefault();
				const currentTouchDistance = this.getDistance(e.touches[0], e.touches[1]);
				const distanceChange = currentTouchDistance - this.lastTouchDistance;

				this.zoom += distanceChange * 0.005;
				this.zoom = Math.max(0.1, Math.min(this.zoom, 2));
				this.fixDocumentAlignement();

				this.lastTouchDistance = currentTouchDistance;
			}
		});
		docController.addEventListener('touchend', (e) => {
			if (e.touches.length < 2) {
				this.lastTouchDistance = null;
			}
		});
	}

	initWheelZoomControl() {
		const docController = document.getElementById('documentController') as HTMLDivElement;
		docController.addEventListener(
			'wheel',
			(event) => {
				if (event.ctrlKey && this.allowZoom) {
					event.preventDefault();
					const delta = Math.sign(event.deltaY);
					if (delta > 0) {
						this.zoomControl('decrease');
					} else {
						this.zoomControl('increase');
					}
				}
			},
			{ passive: false }
		);
	}

	getDistance(touch1: Touch, touch2: Touch): number {
		const dx = touch1.clientX - touch2.clientX;
		const dy = touch1.clientY - touch2.clientY;
		return Math.sqrt(dx * dx + dy * dy);
	}

	setConsultedOnScroll() {
		const documentView = document.getElementById('docView');
		if (documentView) {
			documentView.addEventListener('scroll', () => {
				const scrollStage = documentView.scrollHeight - documentView.offsetHeight;
				const percetenage = (100 * documentView.scrollTop) / scrollStage;

				if (this.progression < percetenage) {
					this.progression = percetenage;

					Array.from(document.getElementsByClassName('sign-btn')).forEach((element) => {
						(element as HTMLElement).style.setProperty('--progression', `${this.progression}%`);
					});
				}

				if (documentView.scrollTop == scrollStage || documentView.scrollTop + 2 >= scrollStage) {
					this.consulted = true;
				}
			});
		}
	}

	/**
	 * Ajuste la taille des contrôles (zoom etc...) du doc
	 */
	adjustDocControlls() {
		const docControlls = document.getElementById('documentControlls');
		const docContainer = document.getElementById('docView');

		if (docContainer && docControlls) {
			docControlls.style.width = (docContainer.offsetWidth - 20).toString() + 'px';
		}
	}

	/**
	 * ajuste la taille du document pour qu'il soit visible correctement
	 */
	adjustDocumentContent() {
		if (this.docContent && this.docView) {
			const top = (this.docContent.offsetHeight * this.zoom - this.docView.offsetHeight + 30) / 4;

			this.docContent.style.top = top + 'px';
		}
	}

	mailToOwner() {
		if (this.webdoc) {
			window.location.href = `mailto:${
				this.webdoc.recipients.filter((recipient) => recipient.type.toLocaleLowerCase() == 'owner')[0].email
			}`;
		}
	}

	async setDocumentSigned() {
		const webdocToken = this.$route.params.token.toString();
		this.signatureLoaded = false;
		await WebdocService.setWebdocSign(webdocToken, this.actions)
			.then(() => {
				if (this.webdoc) {
					this.webdoc.signatory.status = 'signed';
					this.webdoc.recipients.forEach((recipient) => {
						if (recipient.email == this.webdoc?.signatory.email)
							recipient.status = this.webdoc.signatory.status;
					});
					this.hasEveryStakeHolderSigned = this.webdoc.recipients.every(
						(stakeHolder) => stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
					);
				}
			})
			.catch((error: AxiosError) => {
				if (error.response)
					switch (error.response.data) {
						case 'AlreadySign': {
							this.toast.error(this.$t('error.webdocAlreadySign'));
							break;
						}
						case 'ActionAlreadyRegistred': {
							this.toast.error(this.$t('error.webdocActionAlreadyRegistred'));
							break;
						}
						case 'WebdocExpired': {
							this.toast.error(this.$t('error.webdocExpired'));
							break;
						}
						case 'NoStakeholdersFound': {
							this.toast.error(this.$t('error.webdocExpired'));
							break;
						}
						case 'NoWebdocFound': {
							this.toast.error(this.$t('error.webdocNotFound'));
							break;
						}
						default: {
							this.toast.error(this.$t('error.webdocSignatureError'));
							break;
						}
					}
			})
			.finally(() => {
				this.signatureLoaded = true;
			});
	}

	getSignPreview(name: string, width: string, height: string, top: string, left: string) {
		const htmlSignPreview = `<div class="sign-preview" style="clear: both;margin: 0; width: ${width.trim()}px; height: ${height.trim()}px; position: absolute; top: ${top}px; left: ${left}px">
		<div
			class="sign-preview__content"
			style="
				float: left;
				width: ${width.trim()}px;
				height: ${height.trim()}px;
				border: 3px solid #0571BC;
				border-radius: 8px;
				position: relative;
				z-index:100;
			"
		>
			<div
				class="sign-preview__header-text"
				style="
					background: #0571BC;
					color: white;
					border-radius: 10px;
					text-align: center;
					padding: 5px 10px;
					width: fit-content;
					font-family: 'Montserrat', 'Roboto', 'Helvetica', 'sans-serif';
					font-weight: 500;
					position: absolute;
					transform: translate(-50%, -50%);
					z-index: 10;
					left: 50%;
					font-size: ${(Number(width) * 0.015) / 2.222222222222}em;
				"
			>
				${this.$t('sign.preview')}
			</div>
			<div
				class="sign-preview__body"
				style="padding: 8x; padding-top: 20px; text-align: center; position: relative; margin: 0 auto"
			>
				<p class="sign-preview__name" style="font-family: Kalam, cursive; font-size: ${
					Number(width) * ((18 * 0.00566) / name.length)
				}em">${name}</p>
				<p
					class="sign-preview__date"
					style="
						font-size: 8px;
						margin-bottom: 8px;
						font-family: 'Montserrat', 'Roboto', 'Helvetica', 'sans-serif';
						color: #616161;
					"
				>
					${this.$t('sign.the') + ' ' + new Date().toLocaleDateString()}
				</p>
			</div>
		</div>
	</div>`;
		return htmlSignPreview;
	}

	/**
	 * Fait télécharger la facture en PDF
	 * @param facture La facture associé à un contrat
	 */
	async downloadWebdocPDF() {
		if (
			!this.consultation &&
			!this.webdoc?.recipients.every(
				(stakeHolder) => stakeHolder.type == 'Owner' || stakeHolder.status == 'signed'
			)
		) {
			this.toast.error(this.$t('error.webdocNotDone'));
			return;
		} else {
			this.toast.info(this.$t('webdoc.downloading'));
			await WebdocService.getWebdocFile(this.webdocToken)
				.then((versionData) => {
					const fileURL = URL.createObjectURL(versionData);
					const link = document.createElement('a');
					link.href = fileURL;
					link.download = this.webdoc?.contentDocuments[0].title + '.pdf';
					link.click();
				})
				.catch((error) => {
					switch (error.response.data) {
						case 'NotSigned': {
							this.toast.error(this.$t('error.webdocEveryoneMustSigned'));
							break;
						}
						default: {
							this.toast.error(this.$t('error.downloadWebdoc'));
							console.error("📑 Erreur de téléchargement d'un webdoc");
							console.error(error);
							break;
						}
					}

					throw new Error();
				});
		}
	}
}
