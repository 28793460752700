import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { Vue } from 'vue-class-component';
import { useAuthStore } from '@/stores/authentication.store';
import { useToast } from 'vue-toastification';

import GonexaAuthenticationService from '@/services/auth-gonexa.service';

import EmailSent from '@/components/authentication/EmailSent.vue';
import RegisterForm from '@/components/authentication/RegisterForm.vue';
import LoginForm from '@/components/authentication/LoginForm.vue';
import UpdatePasswordForm from '@/components/authentication/UpdatePasswordForm.vue';
import LostPasswordForm from '@/components/authentication/LostPasswordForm.vue';
import LoginAs from './components/authentication/LoginAs.vue';
import ConfirmEmail from './components/authentication/ConfirmEmail.vue';

import HomeView from '@/components/HomeView.vue';
import BillingView from '@/components/billing/BillingView.vue';
import UserView from '@/components/user/UserView.vue';
import WorkpaceView from '@/components/workspace/WorkspaceView.vue';
import ConsumptionView from '@/components/consumption/ConsumptionView.vue';
import CallbackSalesforce from '@/components/salesforce/CallbackSalesforce.vue';
import DeploymentView from '@/components/deployment/DeploymentView.vue';
import SignView from '@/components/sign/SignView.vue';
import ErrorView from '@/components/ErrorView.vue';

const routes: Array<RouteRecordRaw> = [
	{
		path: '/home',
		name: 'home',
		component: HomeView,
		meta: {
			title: 'Accueil',
			requiresAuth: true,
		},
	},
	{
		path: '/',
		redirect: { name: 'home' },
	},

	{
		path: '/register',
		name: 'register',
		component: RegisterForm,
		meta: {
			title: 'Inscription',
			requiresAuth: false,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: LoginForm,
		meta: {
			title: 'Connexion',
			requiresAuth: false,
		},
	},
	{
		path: '/loginas/:username/:token',
		name: 'loginas',
		component: LoginAs,
		meta: {
			title: 'Connexion',
			requiresAuth: false,
		},
	},
	{
		path: '/lost-sent',
		name: 'lost-sent',
		component: EmailSent,
		meta: {
			title: 'Confirmation de réinitialisation',
			requiresAuth: false,
		},
	},
	{
		path: '/email-sent',
		name: 'email-sent',
		component: EmailSent,
		meta: {
			title: 'Confirmation du mail',
			requiresAuth: false,
		},
	},
	// Route pour accéder au formulaire de demande de réinitialisation de mot de passe
	{
		path: '/lost-password',
		name: 'lost-password',
		component: LostPasswordForm,
		meta: {
			title: 'Mot de passe oublié',
			requiresAuth: false,
		},
		beforeEnter: [checkIfDisconnected],
	},
	// Route pour accéder à l'initialisation du mot de passe
	{
		path: '/initialize-password/:emailToken',
		name: 'initialize-password',
		component: UpdatePasswordForm,
		meta: {
			title: 'Initialisation du mot de passe',
			requiresAuth: false,
		},
		beforeEnter: [checkIfDisconnected],
	},
	// Route pour accéder à la réinitialisation du mot de passe
	{
		path: '/update-password/:emailToken',
		name: 'update-password',
		component: UpdatePasswordForm,
		meta: {
			title: 'Mise à jour du mot de passe',
			requiresAuth: false,
		},
		beforeEnter: [checkIfDisconnected],
	},
	// Page pour confirmer la mise à jour du mail d'un compte
	{
		path: '/confirm-email/:emailToken',
		name: 'confirm-email',
		component: ConfirmEmail,
		meta: {
			title: 'Confirmation de votre nouvel e-mail',
			requiresAuth: false,
		},
	},

	{
		path: '/consumption',
		name: 'consumption',
		component: ConsumptionView,
		meta: {
			title: 'Consommation',
			requiresAuth: true,
		},
		beforeEnter: [checkIfOwnerVerified],
	},
	{
		path: '/billing',
		name: 'billing',
		component: BillingView,
		meta: {
			title: 'Factures',
			requiresAuth: true,
		},
		beforeEnter: [checkIfOwnerVerified],
	},
	{
		path: '/user',
		name: 'user',
		component: UserView,
		meta: {
			title: 'Profil et utilisateurs',
			requiresAuth: true,
		},
		beforeEnter: [checkIfOwner],
	},
	{
		path: '/workspaces',
		name: 'workspaces',
		component: WorkpaceView,
		meta: {
			title: 'Environnements',
			requiresAuth: true,
		},
	},
	{
		path: '/deployment',
		name: 'deployment',
		component: DeploymentView,
		meta: {
			title: 'Déploiement',
			requiresAuth: true,
		},
	},
	{
		path: '/callback',
		name: 'callback-salesforce',
		component: CallbackSalesforce,
		meta: {
			title: 'Callback Salesforce',
			requiresAuth: true,
		},
	},
	{
		path: '/webdoc/:token',
		name: 'webdoc',
		component: SignView,
		meta: {
			title: 'Signature éléctronique',
			requiresAuth: false,
		},
	},
	{
		path: '/:pathMatch(.*)*',
		name: 'error',
		component: ErrorView,
		meta: {
			title: 'Erreur!',
			requiresAuth: true,
		},
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

router.beforeEach(async (toRoute) => {
	// Création du titre de la page, affiché sur l'onglet du navigateur
	window.document.title =
		toRoute.meta.title && toRoute.name != 'webdoc' ? "L'App | " + (toRoute.meta.title as string) : "L'App";

	const isConnected = await GonexaAuthenticationService.getLocalUser();

	// Si aucun utilisateur n'est connecté et que la page nécessite un utilisateur connecté, on renvoie vers la page de connexion
	if (isConnected == false && toRoute.meta.requiresAuth == true && toRoute.name != 'webdoc') {
		// On enregistre la page à laquelle l'utilisateur voulait accéder pour le rediriger après connexion
		if (toRoute.name) localStorage.setItem('APP_NEXT_LOGIN', toRoute.name.toString());

		return { name: 'login' };
	}

	// Si un utilisateur est connecté mais que la page  nécessite un utilisateur déconnecté, on redirige vers la page d'accueil
	if (isConnected == true && toRoute.meta.requiresAuth == false && toRoute.name != 'webdoc') {
		return { name: 'home' };
	}
});

function checkIfOwnerVerified() {
	const authStore = useAuthStore();
	const toast = useToast();

	if (authStore.roleName?.toLowerCase() != 'owner' && authStore.companyStatus?.toLowerCase() != 'verified') {
		toast.info(Vue.prototype.$t('error.router'));
		return { name: 'home' };
	}
}

function checkIfOwner() {
	const authStore = useAuthStore();
	const toast = useToast();

	if (authStore.roleName?.toLowerCase() != 'owner') {
		toast.info(Vue.prototype.$t('error.router'));
		return { name: 'home' };
	}
}

function checkIfDisconnected() {
	const authStore = useAuthStore();
	localStorage.removeItem('APP_U');
	localStorage.removeItem('APP_LOG_AS');
	authStore.emptyStore();
	// this.authStore.emitDisconnectToast = true;
}

export default router;
