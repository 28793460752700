import App from './App.vue';
import { createApp } from 'vue';
import router from './router';
import { createPinia } from 'pinia';
import Toast from 'vue-toastification';
import i18n from './i18n';
// import 'vue-toastification/dist/index.css';

// CONFIG STORES PINIA
const pinia = createPinia();

// CONFIG TOASTS
const toastOptions = {
	transition: 'Vue-Toastification__fade',
	maxToasts: 3,
	newestOnTop: true,
	position: 'bottom-center',
	timeout: 5000,
	closeOnClick: true,
	pauseOnFocusLoss: false,
	pauseOnHover: true,
	draggable: false,
	draggablePercent: 0.6,
	showCloseButtonOnHover: false,
	hideProgressBar: true,
	closeButton: 'button',
	icon: false,
	rtl: false,

	containerClassName: 'app-toast',
};

createApp(App)
	.use(i18n)
	.use(router)
	.use(pinia) // Stores
	.use(Toast, toastOptions) // Toasts de notifications
	.mount('#app');
